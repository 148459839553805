@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}


.card {
  width: 250px;
  height: 250px;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  border-radius: 8px;
  color: white;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  cursor: pointer;
}

.card-content {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.card-content .card-title {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
}

.card-content .card-para {
  color: inherit;
  opacity: 0.8;
  font-size: 14px;
}

.card:hover {
  transform: rotateY(10deg) rotateX(10deg) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 1;
}

.card:hover:before {
  transform: translateX(-100%);
}

.card:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 1;
}

.card:hover:after {
  transform: translateX(100%);
}

.form {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 30px 30px -30px rgba(27, 26, 26, 0.315);
}

.form{
  color: royalblue;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form input {
  outline: 0;
  border: 1px solid rgb(219, 213, 213);
  padding: 8px 14px;
  border-radius: 8px;
  width: 100%;
  height: 50px;
}

.form textarea {
  border-radius: 8px;
  height: 100px;
  width: 100%;
  resize: none;
  outline: 0;
  padding: 8px 14px;
  border: 1px solid rgb(219, 213, 213);
}

.form button {
  padding: 8px;
  width: 100px;
  outline: 0;
  border: 0;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  background-color: royalblue;
  color: #fff;
  cursor: pointer;
}

/*
.slider{
  display: grid;
  position: relative;
  place-items: center;
  margin: auto;
  overflow: hidden;
}

.slideTrack{
  display: flex;
  width: calc(250*18);
  animation: scroll 30s linear infinite;
}

@media screen and (max-width: 480px) {
  .slideTrack{
    display: flex;
    width: calc(250*18);
    animation: scroll 50s linear infinite;
  }
}

.slideTrack:hover{
  animation-play-state: paused;
}

.slide{
  align-items: center;
}

.slider::before,
.slider::after{
background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
content: '';
width: 15%;
height: 100%;
z-index: 2;
position: absolute;
}

.slider::before{
top: 0;
left: 0;
}

.slider::after{
right: 0;
top: 0;
transform: rotateZ(180deg);
}

.slide img{
transition: transform 1s;
}

.slide img:hover{
transform: translateZ(100px);
}

@keyframes scroll{
0%{
  transform: translateX(0);
}
100%{
  transform: translateX(calc(-250px * 21));
}
}
*/


